@import '../../styles/variables.scss';

:global {

    .instruction {
     
        &.block-create {
            .header {
                div {

                    max-width: 70%;
                    white-space: pre;

                    h1 {
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }
}