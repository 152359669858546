@import '../../styles/variables.scss';

:global {
    // .app-select {
    //     display: block !important;
    // }
    .dropdown {
        .dropdown-menu {
            .dropdown-item {
                &.custom {
                    display: inline-block;
                    padding: 0px;
                    width: auto;

                    &:hover {
                        box-shadow: none;
                    }
                }
            }
        }
    }

    .batch-action {
        
        .batch-action-dropdown {
            display: inline-block;
            cursor: pointer;

            &.disabled {
                cursor: initial;
            }
        }

        .icon {
            cursor: pointer;
            margin: 0 0.5rem;
            transition: opacity .25s ease-in-out;
            -moz-transition: opacity .25s ease-in-out;
            -webkit-transition: opacity .25s ease-in-out;

            // &:hover {
            //     opacity: 1;
            // }

            &.disabled {
                opacity: .5;
                pointer-events: initial !important;
                cursor: initial;
            }
        }
    }



    .project-table {
        margin-bottom: 1rem;    
        
        
        input[type="checkbox"] {
        &.app-checkbox {
            position: relative;
            opacity: 1;
            pointer-events: initial;
            -webkit-appearance: none;
            border:2px solid $secondaryColor;
            width:20px;
            height:20px;
            padding: 2px;
            background-color: $white;

        }
        &:checked {
            background: $white;
            border-color: $white;

            &::after {
                font-family: "Font Awesome 5 Pro";
                content: " \f00c";
                font-weight: 900;
                color:$secondaryColor;
                font-size:0.8rem;
                margin-left:-1px;
                margin-top:-3px;
                position:absolute;
            }
        }
    }
                            


        table {
            width: 100%;

            // tr {
            //     &.table-border {
            //         border-style: solid !important;
            //         border-width: 2px 0 2px 0 !important;
            //         border-color: rgb(231, 231, 231) !important;
            //     }

            //     &.can-expand {
            //         background: #F7F7F8;
            //     }

            //     &.no-projects  {
            //         background-color: inherit !important;
            //         border-bottom: 0px solid !important;
            //     }
            // }

            td, th {
                padding: .5rem;
                height: 3.5rem;
                font-weight: 400;
                text-align: center;
                vertical-align: middle !important;
            }
            
            th {
                font-weight: bold;
                &.action-header {
                    min-width: 110px;
                }
            }

            .pagination {
                display: table-row;
                background-color: inherit !important;
                td { 
                    a, span {
                        margin-right: .2rem;
                    }
                }
                a {
                    color: $secondaryColor;
                    &:hover, &:focus {
                        text-decoration: underline;
                        color: $secondaryColorLt;
                    }
                    &.disabled {
                        color: $dark-grey;
                        opacity: .3;
                    }
                }
            }

            thead {
                tr {
                    background: $primaryColor;
                    color: $white;

                    .sortable {
                        &:hover {
                            text-decoration: underline;
                            cursor: pointer;
                        }

                        .icon {
                            width: auto;
                            .sort-down {
                                margin-top: .35rem;

                                &.active {
                                    opacity: .5;
                                }
                            }

                            .sort-up {
                                margin-top: .35rem;

                                &.active {
                                    opacity: .5;
                                }
                            }
                        }
                    }
                }
            }


            tbody {
                tr {

                    &.table-border {
                        border-style: solid !important;
                        border-width: 2px 0 2px 0 !important;
                        border-color: $light-grey-2 !important;
                    }

                    &.can-expand {
                        background: $off-white;
                    }

                    &.no-projects  {
                        background-color: inherit !important;
                        border-bottom: 0px solid !important;
                    }

                    .action-area {
                        text-align: right;

                        .action {

                            display: none;

                            font-size: 1.2rem;
                            cursor: pointer;
                            margin-right: .1rem;
                            
                            &.enabled {
                                display: inline-block;
                            }

                            &:hover {
                                color: $black-1;
                            }

                            &:last-child {
                                margin-right: .3rem;
                            }
                        }
                    }

                    &:hover {
                        background-color: $row-hover-color;

                        .action-area {
                            .action {
                                display: inline-block;
                            }
                        }
                    }

                    &.selected {
                        background: $secondaryColorLt;
                    }
                }
            }
        }
    }
}