@import '../../styles/variables.scss';

.form-group {
  margin: 0px !important;
}

.has-search .form-control {
    padding:.375rem .375rem .375rem 2.75rem;
    width:100%;
    min-width:300px;
}


.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: auto;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: $grey;
}

input[type=text]:focus, .form-control:focus {
  box-shadow: 2 2 5px $secondaryColorLt;
  border: 1px solid $secondaryColorLt;
}