@import "../../styles/variables.scss";
@import "../../styles/scss/fontawesome.scss";

.collection-delete-modal {
    margin-top: 3rem;

    .delete-title-notice {
        margin-top: 0.5rem;
    }
    
    .delete-title-separator {
        width: 150px;
        margin: inherit;
    }
    
    // .delete-content-notice {
    //     margin-top: 4rem;   
    // }
    
    .collection-delete-content {
        max-width: 1140px;
    }

       
}
