.grazing-practice-picker {
    margin-top: 10px;

    .picker-container {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        div {
            margin: 0 10px;
        }
    }
}
