@import "../../styles/variables.scss";

.form-step-btn {
    &.btn {
        /* Safari 3-4, iOS 1-3.2, Android 1.6- */
        -webkit-border-radius: 50%;
        /* Firefox 1-3.6 */
        -moz-border-radius: 50%;
        /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */
        border-radius: 50%;
        background-color: $grey;
        color: $white;
        width: 2rem;
        height: 2rem;
        padding: 0.15rem 0.5rem;

        &:disabled.btn-primary,
        &.btn-primary {
            background-color: $primaryColor !important;
            border-color: $primaryColor;
            color: $white;
        }
        &:focus,
        &:active,
        &:active:focus {
            outline: 0;
            // if you like sparkling water feel free add box-shadow :)
            box-shadow: none ;
        }
        &.btn-default:not([disabled]):not(.disabled):active {
            background-color: $grey !important;
        }
    }

    &.form-stepper-current,
    &.form-stepper-current:focus
     {
        box-shadow: 0px 0px 3px 3px $secondaryColor;
    }
}

.form-stepper-separator {
    display: inline-block;
    height: 0.3rem;
    width: 3rem;
    background-color: $grey;

    &.seperator-completed {
        background-color: $primaryColor;
    }
}


.survey-container {
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    .survey-content-container {
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        position: absolute;
        overflow-y:auto;

        h1 {
            font-weight:$regular;
            margin-bottom:1.5rem;
        }
        h2 {
            color:$dark-grey;
            font-size:1.6rem;
            margin:1.5rem 0 1rem;
        }

        &:not(.mini-survey) {
            padding: 1.5rem 5rem 0 5rem;
        }

        &.mini-survey-content-container {
            padding-top: 0;
            padding-left: 25px;
            padding-right: 25px;
            height: 100%;
            font-size: 0.85rem;
            h1 {
                font-size: 1.5rem;
            }
        }

        &.mini-survey {
            display: flex;
            flex-direction: column;
            padding: 0;
            color:$dark-grey;
            form {
                margin-top:1rem;
                input[type=text] {
                    padding:0.3rem;
                }
                .response {
                    margin-bottom:1rem;
                }
            }
            .mini-survey-control {
                margin: 0;
                width: 100%;
                .mini-pagination {
                    padding-top:0.75rem;
                    .page-number-input {
                        display: inline;
                        font-weight:$bold;
                    }
                }
                .btn-as-text {
                    font-size: 1.5rem;
                } 
            }
        }

    }

    .mini-survey-header {
        margin: 0;
    }

    .mini-survey-control {
        
    }
}

.survey-control-container {
    padding: 0.65rem;
    border:1px solid $light-grey;
    .help-block {
        font-size:0.95rem;
        margin-bottom:1rem;
        text-align:center;
    }
    > div:first-child {
        flex-grow:1;
    }
}
