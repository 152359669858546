@import "../../styles/variables.scss";
.survey-fixer-container {
    display: inline;
    margin-left: 2.5px;

    .dropdown-menu.issue-detail-wrapper {
        width: 600px;
        height: 400px;
        margin-left: -610px;
        margin-top: -410px;

        .action-information-page-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 100%;
            width: 100%;
        }

        div[role="menu"] {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
        }

        .issue-detail-helper {
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            justify-content: center;

            h2 {
                color: $dark-grey;
                strong {
                    color: $primaryColor;
                }
            }
        }

        .issue-detail-action {
            display: flex;
            justify-content: center;
        }
    }
}
