@import "../../styles/variables.scss";

.manage-account-modal {
    .change-password {
        padding-top:2rem;
        input {
            width:100%;
        }
    }

} 

// .change {
//     width: 100%;
//     color: $white;
//     background-color: $primaryColor;
//     padding: 0.5rem 0;
//     border-radius: 5px;
//     font-size: $font-size-bigger-btn;
// }

