

:global {
    .combo-series {
        // height: 400px;
        height: 100%;
        .chart {
            height: 100%;

            .chart-area {
                height: 100%;
            }
        }
    }
}