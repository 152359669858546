@import '../../styles/variables.scss';

:global {

    .instruction {
        padding-top: 1.5rem;
        overflow-wrap: break-word;

        .upload-link {
            color: $secondaryColor;
            cursor: pointer;
            font-weight: 400;

            &.disabled {
                color: $dark-grey;
            }

            .icon {
                color: inherit;
            }
        }

        .header {
            // display: flex;
            // align-items: center;
            // justify-content: center;
            margin-bottom: 2rem;

            .icon {
                border-radius: 72px;
                border: 2px solid $primaryColor;
                font-size: 4rem;
                height: 70px;
                margin-left: 0px;
                margin-right: 0.7rem;
                padding: .85rem;
                width: 70px;
            }
        }

            
        .icon {
            
            color: $primaryColor;
            margin: 0px .2rem;
        }

        h2 {
            color: inherit;
            margin-bottom: 1rem;
        }

        h3 {
            
        }
    }

    .report {

        text-align: center;

        .improvement-chart {
            height: 261px !important;
            padding-left: 10px;
            padding-right: 10px;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .cost-header {
            color: $grey !important;
            margin: 1rem 0 0.2rem 0;
        }

        .measure {
            font-weight: 400;
            padding-top: 0.8rem;
            text-align: right;
        }

        .amount {
            font-size: 1.4rem;
            font-weight: 400;
            padding-top: 0.4rem;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;

            &.has-result {
                color: $primaryColor;
                font-size: 2rem;
                font-weight: $semi-bold;
                padding-top: 0;

            }
        }

        .unit {
            font-weight: $regular;
            padding-top: 0.8rem;
            text-align: left;
        }
    }
    @media (max-width:1500px) and (min-width:992px) {
        .instruction {
            .header {
                .icon-container {
                    display:block;
                }
            }
        }
    }

}

