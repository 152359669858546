@import '../../styles/variables.scss';

div.notification {
    background-color: rgba($color: $black-2, $alpha: 0.9);
    &.error {
        background-color: rgba($color: $alert, $alpha: 0.9);
    }

    color: $light-grey;

    font-weight: 500;

    padding: 1rem;
    min-width: 450px;
    // margin: 1rem;
    // margin-right: 7rem;
    border-radius: 5px;

    .Toastify__close-button {
        color: $light-grey;

        &>svg {
            height: 24px;
            width: 20px;
        }
    }
}
