@import "../../styles/variables.scss";

.survey-save-fix-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    .radio-container {
        width: auto;
        margin-bottom: 15px;
    }

    .dropdown-item {
        color: $white !important;
    }
}
