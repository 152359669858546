@import '../../styles/variables.scss';

.funding-source-select {
    width:100%;
}

.funding-tab {
    margin:0 1rem;

    h2 {
        margin-bottom:0;
    }
    
    table {
        margin-top:1rem;
        td, th {
            padding: 0.8rem;
        }
        tbody {
            tr {
                &:nth-child(odd) {
                    background-color: $off-white;
                }
                > td:first-child {
                    font-weight:$semi-bold;
                }
            }
        }
    }
}