@import "source-sans-font.scss";
// $fa-font-path: "./webfonts";

// COLOUR VARIABLES
// Colours


$primaryColor: #ff671b;
$primaryColorLt: #ff894e;
$secondaryColor: #08bfdd;
$secondaryColorLt: #5ce3f9;
$alert: #d2272e;

// Monochromes
$black-1: #252628;
$black-2: #000002;
$dark-grey: #77777a;
$grey: #acacae;
$light-grey-1: #dcdcdc;
$light-grey-2: #e7e7e7;
$light-grey: #e3e4e5;
$off-white: #f7f7f8;
$white: #ffffff;

//Semi-tansparent
$manage-account-bg: rgba(255, 255, 255, 0.95);
$row-hover-color: rgba(0,0,0,.075);

// FONT STUFF
$font-size-text: 16px;
$font-size-btn: 18px;
$font-size-small-bigger-btn: 20px;
$font-size-bigger-btn: 24px;
$font-size-heading: 36px;
$font-size-14rm: 1.4rem;

// Font stack
$font-stack: 'Source Sans Pro', 'Helvetica', 'Arial', 'sans-serif',
sans-serif !default;

// Font-weights
$light: 300;
$regular: 400;
$semi-bold: 600;
$bold: 700;
$black: 900;