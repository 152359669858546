@import "../../styles/variables.scss";
// @import '../../../node_modules/leaflet/dist/leaflet.css';
// @import '../../../node_modules/leaflet-draw/dist/leaflet.draw.css';
// @import '../../../node_modules/leaflet-toolbar/dist/leaflet.toolbar.css';

:global {
    .sr-only {
        display: none;
    }

    .leaflet-map {
        height: 610px;
    }

    .map-legend {
        padding-top: 0.3rem;
        p {
            display: inline-block;
            font-size: 0.9rem;
            font-weight: 300;
            margin-right: 1rem;
        }

        .shading {
            background: $secondaryColor;
            border: solid 4px $secondaryColor;
            display: inline-block;
            height: 12px;
            opacity: 0.6;
            width: 12px;

            &.conflict {
                background: $alert;
                border-color: $alert;
            }

            &.selected {
                background: $primaryColor;
                border-color: $primaryColor;
                opacity: 1;
            }
        }
    }

    .app-map {
        width: 100%;
        height: 100%;

        .leaflet-top,
        .leaflet-bottom {
            z-index: 900 !important;
        }

        .leaflet-draw-section {
            font-family: "Font Awesome 5 Pro";
            font-weight: 400;
            font-size: 0.82rem;
            -webkit-font-smoothing: antialiased;
            display: block;
            font-style: normal;
            font-feature-settings: normal;
            font-variant: normal;
            text-rendering: auto;
            .leaflet-draw-draw-polygon {
                background-image: none !important;
                background-position: 0;

                &:disabled {
                    background-position: 0;
                }

                &:before {
                    content: "\f5ee";
                    text-align: center;
                }
            }

            .leaflet-draw-edit-edit {
                background-image: none !important;
                background-position: 0;

                &:disabled {
                    background-position: 0;
                }

                &:before {
                    content: "\f040";
                    text-align: center;
                }
            }

            .leaflet-draw-edit-remove {
                background-image: none !important;
                background-position: 0;

                &:disabled {
                    background-position: 0;
                }

                &:before {
                    content: "\f2ed";
                    text-align: center;
                }
            }
        }

        .leaflet-control {
            .leaflet-draw-section {
                &:first-child {
                    .leaflet-draw-actions {
                        //Hack: hide the first child of the draw actions button (Finish button is always first)
                        //this is done to make sure users complete a valid polygon. The current logic immediately save the polygon
                        //when it is complete which should be good enough, there is no real use of the finish button..
                        //TM - 01/02/2021 - Remove this if there is a way to remove the button directly programmaticaly..
                        li {
                            &:first-child {
                                // visibility: hidden;
                                // width: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .select-toolbar {
        list-style: none;
        transition: all ease 1s !important;
        border: 0px;

        .leaflet-toolbar-icon {
            width: auto !important;
            padding: 0px 10px !important;
            text-transform: uppercase;
            color: $dark-grey;
            font-size: 0.8rem;
            font-weight: 550;

            &:hover {
                color: $black-1;
            }
        }

        &.disabled {
            // background: #e3e4e5;
            pointer-events: none;
            opacity: 0.65;
            .leaflet-toolbar-icon {
                color: #bbb;
            }
        }
    }

    .block {
        fill: $secondaryColor !important;
        fill-opacity: 0.6 !important;
        stroke-width: 4px;
        stroke: $secondaryColor;

        &.error {
            fill: $alert !important;
            fill-opacity: 0.6 !important;
            stroke: $alert;
            stroke-width: 4px;
        }

        &.selected {
            fill: $primaryColor !important;
            fill-opacity: 0.6 !important;
            stroke: $primaryColor;
            stroke-width: 4px;
        }

        &:hover {
            fill-opacity: 1 !important;
        }

        &.hovered {
            fill-opacity: 1 !important;
        }

        &.extent-enabled {
            stroke-dasharray: 10;
            stroke: yellow;
        }

        &.highlighted {
            stroke-width: 4px;
            stroke: $white;
            stroke-opacity: 1;
        }

        &.line-string {
            fill: none !important;
        }
    }

    .map-tooltip {
        z-index: 9999;

        background: rgba(37, 38, 40, 0.8);
        border-radius: 5px;
        color: $white;
        max-width: 400px;
        min-width: 80px;
        overflow: hidden;
        padding: 0.75rem;
        text-align: left;
        z-index: 999999;

        p {
            margin-bottom: 0.3rem;
        }
    }
}
