@import '../../styles//variables.scss';

/* Manage account modal */
.manage-account-modal {
   

    .card {
        box-shadow: none;
    }

   .nav {
        background-color: inherit;
        box-shadow: none;
        color: $dark-grey;

        .nav-item {
            background-color: $light-grey;
            border-radius: 3px;
            border: none;
            cursor: pointer;
            font-size: 1.5rem;
            font-weight: $regular;
            margin-right: 15px;
            padding: 0.5rem;

            &:last-child {
                margin-right:0;
            }
            
           &.active,
           &:hover,
           &:active,
           &:focus {
                background-color: $secondaryColor;   
                color: $white;
            }
        }

   }

   .tab-content {
       padding:1rem;
       padding-top:1rem;
   }
}

.form-button-container {
    margin-top:2rem;
    display:flex;
    justify-content: center;
}

// overwrite bootstrap max-width;
// @media (min-width: 576px) {
//     div.modal-dialog {
//         max-width: 100%;
//     }
// }