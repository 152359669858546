@import '../../styles/variables.scss';

.radio-container {

    .custom-radio :checked~.custom-control-label::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8' %3e%3ccircle r='3' fill='%23ff671b'/%3e%3c/svg%3e");
    }

    .custom-control {
        .custom-control-input {
            &:checked ~ .orange-check-form::before {
                background-color: $white;
                border-color: $primaryColor;
                border-width: 2.5px;
            }
        
            &:active ~ .orange-check-form::after {
                background-color: white;
                border-radius: 50%;
                border: 1px solid #77777a;
            }
        
        }

        .custom-control-label {
            &:after {
                position: absolute;
            top: 4px;
            left: -30px;
            width: 16px;
            height: 16px;
            }
        
            &:before {
                position: absolute;
                top: 4px;
                left: -30px;
                display: block;
                width: 16px;
                height: 16px;
            }
        }
    } 
}

.radio-container, .radio-category-container {
    min-width: 70px;
    width: 70px;
    text-align: center;
}