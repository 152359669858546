@import "../../styles/variables.scss";

/******************
  LOGIN/REGISTER  */

.account {
    background: $black-2;
    color: $white;
    height:100%;
    /*    margin-bottom:-20px;
    min-height:800px;*/
    text-align: center;
    width:100%;
    position: relative;
    &.login, 
    &.request, 
    &.reset { 
        min-height:900px; 
    }
    &.register { min-height:1050px; }

    .container { max-width:680px; }

    main {
        background:url('../../styles/images/login-background-1920x1080-grad.jpg') center;
        background-size: cover;
        min-height:800px;
        .container { 
            padding-top:50px; 
        }
    }
    h1 { 
        color:$white; 
        font-size:3.5rem;
        font-weight:800;
        margin-bottom:1rem;
    }
    h2 { 
        font-weight: 300; 
        color: $white;
        font-size: 1.4rem;
    }
    p { 
        &.msg {
            margin-top:2rem;
        }
    }
    a { 
        color:$white;
        font-weight:600;
        text-decoration: underline; 
        &:hover {
            text-decoration:none;
        }
    }
    form { 
        margin-top:2rem; 

        .btn {
            border-radius:.25rem;
            padding:0.7rem;
            font-size: 1.2rem;
            &.btn-color {
                background:$white !important;

                &:hover,
                &:focus,
                &:active {
                    background: $primaryColorLt !important;
                    color: $white;
                }
            }

        }

        .form-control {
            background:rgba(255,255,255,0.2);
            border:2px solid $white;
            border-radius:0.25rem;
            color: $white;
            font-size:1.2rem;
            padding:0.6rem;
            width:100%;
            &:focus {
                background:rgba(0,0,0,0.1);
                border-color:$secondaryColor;
                color:$white;
            }

            &::placeholder {
                color:$off-white;
            }
            &:focus::placeholder {
                color:$white;
            }   
        } 
        

    }
    .help-block {
        color:$white;
    }
    .actions {
        margin-top:1rem;
        text-align:center;
        p {
            text-align:center;
        }
    }


    footer {
        background:$black-2;
        bottom:0;   
        position:absolute;
        width:100%;
        p { 
            margin-bottom:0; 
            padding-bottom:1rem;
        }
    }
}