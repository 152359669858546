@import 'src/styles/variables.scss';

.table-wrapper {
    &.block-create {
        display: none;
    }
}
.map-chart-wrapper {
    border-top:$off-white solid 1px;
}


@media (min-width:1200px) {
    .collection-menu {
        padding-right:0;
    }
    .toolbar {
        padding-left:0;
        margin-left:-15px;
    }
    .user-account {
        padding-left:0;
    }
}