@import "../../styles/variables.scss";

.collection-import-modal,
.collection-import-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    width: 100%;

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-grow: 1;

        p {
            font-size: 1.1rem;
            font-weight: $regular;
            margin-bottom: 1rem;
        }
        h3 {
            margin-bottom: 1rem;
            margin-top: 1rem;
        }
    }

    .action-container {
        display: flex;
        justify-content: center;
        padding: 25px;
        width: 100%;
    }
}

.button-version {
    font-size: 1.1rem !important;
}
