@import "../../styles/variables.scss";
@import "../../styles/scss/fontawesome.scss";

:global {

    .collection-newer-content {


    }
}
