@import '../../styles/variables.scss';

$inputHeight: 48px;

input.user-invite-input, 
button.btn.invite-user-trail-btn, 
div.user-input-container  {
    height: $inputHeight;
}

.user-list {
    padding: 0.75rem;
    height:auto;
    .row {
        padding-right:4rem;
    }
    .col-auto {
        line-height:2;
    }
}

.user-invite-input, 
.user-invite-container {
    max-width: 400px;
    width: 400px;
    
    .invite-user-trail-btn {
        margin: 0;

        svg {
            margin-right:0;
        }
    }    
}

div.user-invite-container .tooltip.show .arrow {
    display: none;
}

.group-member {
    // display: inline-block;
    color: $dark-grey;
    // text-align: center;

    &.pending-member {
        color: $primaryColor;
    }
}

.group-manage-users {
    color: $dark-grey;

    &.higher-perms {
        color: $secondaryColor;
    }
}

.remove-user-icon {
    font-size: 0.75rem;

    margin-left: 0.25rem;

    &:hover {
        color: $alert;
        cursor: pointer;
    }
}



.dropdown-menu {
    &.invite-user-dropdown-menu {
        transform: none !important;
        left: unset !important;
        right: 0px !important;
        top: 46px !important;

        .dropdown-item {
            &:hover,
            &:active,
            &:focus,
            &:not([disabled]):not(.disabled):active {
                color: $dark-grey !important;
                box-shadow: none;
                background-color: $light-grey;
            }

        }       
        
    }

}



