@import "../../styles/variables.scss";
@import "../../styles/scss/fontawesome.scss";


.btn-group {
    &.toggle-btn {
        .btn {
            border: none;
            font-size:1.4rem;
            font-weight: $semi-bold;
            &.btn-default {
                background: $light-grey !important;
                color:$dark-grey;
            }
    
            &:hover:enabled {
                background: $secondaryColor !important;
                color: $white !important;
                opacity: .8;
                // color: #fff !important;
                // background-color: #868e96;
                //border-color: $dark-grey;
            }
    
            &:disabled {
                opacity: 0.5;
            }
    
            &.non-selected {
    
            }
            &.selected {
                background: $secondaryColor !important;
                color: $white !important;
            }
        }
    }    
}