@import "./variables.scss";
@import "../styles/scss/solid.scss";
// @import '@fortawesome/fontawesome-pro/css/all.min.css';
// @import "~bootstrap-css-only/css/bootstrap.min.css";
// // @import "~mdbreact/dist/css/mdb.css";
// * {
//     padding: 0;
//     margin: 0;
// }


// Global 
html, body, #root, .App {
    height: 100%
}

html {
    font-size: $font-size-btn;
    height: 100%;
    max-height: 100%;
    position: relative;
}

body {
    color: $dark-grey;
    font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
    font-weight: $light;
    height: 100%;
    line-height: 1.4;
    /* Margin bottom by footer height 
    margin-bottom: 50px;
*/

    &.hasOpenedModals {
        overflow: hidden;
    }
}

/*******************
    TYPOGRAPHY  */

/* size */
.small, p { font-size: 1.1rem; }
.medium, h2 { 
    font-size: 1.4rem;
}
.big, h1 { 
    font-size: 2.6rem; 
    font-weight: $regular;
}
.bigger { font-size: 3.8rem; }
.huge { font-size: 6rem; }

/* weight */

.light { font-weight: $light; }
.regular, h1, h2, h3 { font-weight: $regular; }
.bold, strong { font-weight: $semi-bold; }
.heavy { font-weight: $black; }

h3 { 
    font-size: 1.4rem;
}

h4 {
    font-size: 1.5rem;

    &.big {
        font-size: 1.9rem;
        font-weight: $regular;
    }

    &.big-ex {
        font-size: 2rem;
        font-weight: $regular;
    }
}

h1, h2, .h1, h3 {
    color: $primaryColor;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-weight: $regular;
}

@mixin global-font-size {
    font-family: $font-stack;
    font-weight: $light;
    font-size: $font-size-btn;
    line-height: 1.3;
}

html > body {
    color: $dark-grey;
    font-family: $font-stack;
    font-weight: $light;
    height: 100%;
    line-height: 1.4;
}

body,
p,
ul,
li {
    @include global-font-size();
}

strong,
p strong,
b, 
bold {
    font-weight: $semi-bold;
}
label {
    font-weight:$regular;
}

/*******************
  LINKS & BUTTONS  */
  a, a:visited, .upload-link {
    color: $secondaryColor;
    cursor: pointer;
    font-weight: $regular;
}

    a:focus,
    a:active,
    a:hover {
        color: $secondaryColorLt;
        text-decoration: underline;
    }

    // a, a.btn, .btn, input[type=submit] {
    //     font-weight: $semi-bold;
    //     -webkit-transition: all ease 1s;
    //     -moz-transition: all ease 1s;
    //     -o-transition: all ease 1s;
    //     transition: all ease 1s;
    //     text-decoration: none;
    // }

/* BUTTONS */

.btn {
    text-transform: none;
    background: transparent;
    border: 2px solid transparent;
    box-shadow: none;
    font-size: $font-size-btn;
    font-weight: $bold;
    padding:.5rem 1rem;
    cursor: pointer;
    &-color {       
        color: $primaryColor;
    }
    &:hover {
        box-shadow:none;
        color:$primaryColorLt;
    }

    &:focus {
        outline:none !important;
        box-shadow:none;
    }

    &.btn-primary,
    &:active,
    &.btn-primary:not([disabled]):not(.disabled):active {
        background: $primaryColor !important;
        border-color: $primaryColor;
        color:$white;
        box-shadow:none;
        &:hover, 
        &:focus,
        &:active {
            background: $primaryColorLt !important;
            border-color: $primaryColorLt;
            box-shadow:none;
        }
    }
    &.btn-secondary {
        background: $secondaryColor !important;
        border-color: $secondaryColor;
        color:$white;
        &:hover, 
        &:focus,
        &:active,
        &:not([disabled]):not(.disabled):active {
            background: $secondaryColorLt !important;
            border-color: $secondaryColorLt;
            box-shadow:none;
        }
    }
    &.btn-default {
        background: $grey !important;
        border-color: $grey;
        color:$white;
        &:hover, 
        &:focus,
        &:active,
        &:not([disabled]):not(.disabled):active  {
            background: $dark-grey !important;
            border-color: $dark-grey;
            box-shadow:none;
        }
    }
    &.btn-outline-primary {
        background: transparent;
        border-color: $primaryColor !important;
        color:$primaryColor !important;
        &:hover, 
        &:focus,
        &:active,
        &:not([disabled]):not(.disabled):active  {
            background: $primaryColorLt  !important;
            border-color: $primaryColorLt !important;
            color: $white !important;
            box-shadow:none;
        }
        &:disabled {
            border-color: $light-grey !important;
            color:$grey !important;
        }
    }
    &.btn-outline-secondary {
        background: transparent;
        border-color: $secondaryColor !important;
        color:$secondaryColor !important;
        &:hover, 
        &:focus,
        &:active,
        &:not([disabled]):not(.disabled):active {
            background: $secondaryColorLt !important;
            color: $white !important;
            box-shadow:none;
        }
    }
    &.btn-outline-default {
        background: transparent;
        border-color: $dark-grey !important;
        color:$dark-grey !important;
        &:hover, 
        &:focus,
        &:active,
        &:not([disabled]):not(.disabled):active {
            background: $grey !important;
            color: $white;
            box-shadow:none;
        }
    }
    &.btn-color {
        background: transparent;
        border-color: transparent;
        color:$primaryColor;
        box-shadow:none;
        &:hover, 
        &:focus,
        &:active,
        &:not([disabled]):not(.disabled):active {
            background: transparent !important;
            border-color:transparent;
            color: $primaryColorLt;
            box-shadow:none;
        }
    }
    &:disabled {
        background: $light-grey !important;
        border-color: $light-grey;
        color:$grey;
        &:hover, 
        &:focus,
        &:active {
            box-shadow:none;
        }
    }

     &.btn-lg {
         font-size: 1.4rem;
     }

     &.btn-as-text {
         padding:0;
         color: $secondaryColor;
        &:hover {
            color:$secondaryColorLt;
        }
        &:active,
        &:focus,
        &:active:hover {
            background:$white !important;
            border-color: $white;
        }
        &:disabled {
            background:$white !important;
            border-color: $white;
            color:$grey;
        }

     }

}




/* INPUTS */

input:not([type=submit]),
input:not([type=button]),
select, 
textarea,
.custom-select {
    border: 1px solid $secondaryColor;
    border-radius:3px;
    font-size:1.1rem;
    font-family: $font-stack;
    font-weight:$regular;
    padding: 1rem;
    width:80%;
    height:auto;
    margin:0 auto;
    
    // &.form-control {
    //     border: 1px solid $secondaryColor;
    //     padding: 1rem;
    // }
}

input[type="checkbox"] {
    &.app-checkbox {
        position: relative;
        opacity: 1;
        pointer-events: initial;
        -webkit-appearance: none;
        border:2px solid $secondaryColor;
        width:20px !important;
        height:20px;
        padding: 2px;
        background-color: $white;
        &:focus,
        &:active {
            outline:none;
        }

        &:checked {
            border-color: $secondaryColor;
            background-color:$secondaryColor;

            &::after {
                font-family: "Font Awesome 5 Pro";
                content: " \f00c";
                font-weight: 900;
                color:$white;
                font-size:0.8rem;
                margin-left:-1px;
                margin-top:-3px;
                position:absolute;
            }
        }
    }
}

.form-group {
    // display: flex;
    // justify-content:center;
    text-align:center;
    .help-block {
        text-align:left;
        margin-left:10%;
    }
}

.funding-form {
    h2 {
        text-align:center;
    }
    label {
        color: $grey;
        display:block;
        font-weight: $semi-bold;
        text-align:left;
        margin-left:10%;
    }
    select {
        width:90%;
    }
}

/* Form feedback */
.help-block { 
    margin-top: 0.25rem;
    margin-bottom:0.5rem;
    color: $primaryColor;
    font-size: 1.1rem;
    font-weight: $regular;
}
.error-block {
    color: $alert;
    font-weight: $bold;
}
.form-helper {
    padding:2rem 0;

    p {
        text-align:center;
        margin-bottom:0;
        strong {
            color: $primaryColor;
            font-weight: $semi-bold;
        }
    }
}


body .modal {
    overflow-y: auto;
}
.tool-modal-icon {
    font-size: 45px;
}

.form-element {
    margin-top: 2rem;
    margin-bottom: 2rem;
}
@mixin inp-placeholder-opacity {
    opacity: 0.9;
    color: $grey;
}

// style the placeholder opacity of all inputs
input.form-control {
    &::placeholder {
    // chrome, opera, safari
    // &::-webkit-input-placeholder,
    // // ie 10+
    // &:-ms-input-placeholder,
    // // firefox 19+
    // &::-moz-placeholder,
    // // firefox 18-
    // &:-moz-placeholder {
        @include inp-placeholder-opacity;
    }
}



.icon-text-left-spacing {
    margin-left: 5px;
}
.icon-text-right-spacing {
    margin-right: 1em;
}

.dropdown-item.hide-hover {
    &:hover {
        box-shadow: none !important;
        background-color: inherit !important;
    }
}

.mini-project-action-popup {
    &.batch-action-delete, &.batch-action-move {
        width: 370px;
    }

    .batch-action-delete {
        p strong {
            color: $dark-grey;
        } 
    }
}

// SCROLLBAR //
.custom-scrollbar {
    overflow: hidden;

    &:hover {
        overflow: auto;
    }
}

.custom-scrollbar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}
.custom-scrollbar::-webkit-scrollbar {
    width: 10px;
    background-color: #F5F5F5;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #ACACAE;
}

.contentEditable {
    &:hover, &:focus, &.in-edit-mode {
        border-bottom: solid 1px $secondaryColor;    
    }

    &:focus {
         outline: 0px solid transparent; 
    }
}

@media (min-width:1400px) {
    .col-xxl-1 {
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }
    .col-xxl-2 {
        flex: 0 0 16.666666%;
        max-width: 16.666666%;
    }
    .col-xxl-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-xxl-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .col-xxl-5 {
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }
    .col-xxl-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-xxl-7 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-xxl-8 {
        flex:0 0 66.666667%;
        max-width: 66.666667%;
    }
    .col-xxl-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-xxl-10 {
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }
    .col-xxl-11 {
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }
    .col-xxl-12 {
        flex:0 0 100%;
        max-width: 100%;
    }
}
