@import '../../styles/variables.scss';

#multiselectContainerReact {
    border-bottom: 1px solid $secondaryColor; 

    .chip {
        background: $secondaryColor;
        color: white; 
    }
    
    .optionContainer {
        width: auto !important;

        .option {
            background: $white;
            color: #77777a;
        }

        :hover {
            color: white;
            background: $secondaryColor;
        } 
    }

    .searchBox {
        font-weight: bold;
        border: 0px; 
        
        &::placeholder {
            border: none;
        }
    }
}

._2iA8p44d0WZ-WqRBGcAuEV {
    border: none;
}

div.multiselect__control,
.css-1fhf3k1-control {
    border: 1px solid $secondaryColor;
    font-weight: $regular;
    padding:0.7rem 0.4rem;
    cursor:pointer;
    &:hover {
        border: 1px solid $secondaryColor;
    }
    &.multiselect__control--is-disabled {
        border: 1px solid $light-grey;

        .multiselect__placeholder {
            color:$light-grey-1;
        }

        svg {
            fill:$light-grey-1;
        }
    }

    .css-1okebmr-indicatorSeparator {
        display:none;
    }
    svg {
        fill: $secondaryColor;
        height:30px;
        width:30px;
    }
}

.select-soil {
    margin-bottom:4rem;
}

div.multi-select__control--is-focused {
    box-shadow: 0 0 0 1px $secondaryColor;
    // border-color: $secondaryColor;
    
    &:hover {
        border-color: $secondaryColor;
    }
}
//selected pills
.css-1rhbuit-multiValue {
    background-color: $secondaryColor !important;
    border-radius: 50px !important;
    padding: 0 10px !important;
    
    //label
    .css-12jo7m5 {
        background-color: $secondaryColor;
        color: $white;
        font-weight: $semi-bold;
    }
    //remove
    .css-xb97g8 {
        padding-right:0;
        svg {
            fill:$white;
            margin-right:0;
            width:20px;
            height:20px;
        }        
    }
}
