@import '../../styles/variables.scss';


.export-btn {
    > .btn {
        background: $white !important;
        border-color: $secondaryColor;
        color:$secondaryColor;
        &:hover, 
        &:focus,
        &:active,
        &:not([disabled]):not(.disabled):active {
            background: $secondaryColorLt !important;
            color: $white;
            box-shadow:none;
            border-color: $secondaryColorLt;
        }
        &:disabled {
            background: $light-grey !important;
            border-color: $light-grey;
            color:$grey;
        }
    }
    

    .dropdown-menu {
        button.dropdown-item {
            box-shadow: none;
            color: $dark-grey !important;

            &:hover {
                color: $black-1 !important;
                box-shadow: none;
            }
        }
            
    }

}
