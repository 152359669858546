@import '../../styles/variables.scss';
.active-collection {
    color: $primaryColor;
    font-size: 2.2rem;
    font-weight: 400;
    display: inline-block;
    word-break: break-word;
    white-space: pre-wrap;
}

.collection-selector {
    display: inline-block;
    margin-left: .2rem;
    box-shadow: none;
    border: none;

    button {
        margin-top: -5px;
        padding: 0px;

        .heading-style {
            font-size: 2rem;
            font-weight: 400;
            padding: 0px;
        }
    }


    .collection-menu-content {
        min-width: 460px;
            width: 300px;
            & a {
                word-wrap: break-word;
                white-space: normal;
                --max-lines: 3;
            }

        > h5 {
            font-size: 0.75rem;
        }
        
        &:focus {
            outline: none;
        }

        .dropdown-header {
            // display: flex;
            justify-content: space-between;
            font-weight: $semi-bold !important;
            font-size: 1.2rem !important;
            padding:0.8rem 0.8rem 0.3rem 0.8rem;
            
            &:focus {
                outline: none;
            }
            svg {
                margin-left:0.75rem;
            }
            .blue-header,
            .grey-header {
                cursor:pointer;
            }
            
        }
        // @mixin header-style($color) {
        //     display: flex;
        //     justify-content: space-between;
        //     color: $color;
        //     font-weight: $semi-bold;
        //     font-size: $font-size-14rm;
        //     cursor: pointer;
        // }
        .dropdown-item {
            padding-right: 0px !important;
            outline: 0px;
        }
    }
}



.scrollable-menu {
    height: auto;
    max-height: 300px;

    overflow-x: hidden;
    padding: 0px;
    overflow-y: auto;
    box-sizing: content-box;

    margin-top: .5rem;
    margin-bottom: .5rem;
}





.grey-header {
    color: $dark-grey;
    &:hover {
        color: $grey;
    }
}

.blue-header {
    color:$secondaryColor;
    &:hover {
        color: $secondaryColorLt;
    }
}

.line-collection {
    // width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    word-break: break-all;
    color: $dark-grey;
    &:hover div {
        visibility: visible !important;
    }

    .collection-selected {
        color: $primaryColor
    }

    .collection-title {
        display: inline-block
    }

    svg.svg-inline--fa:last-of-type {
        margin-right: 15px;
    }
}

svg+svg {
    margin-left: 5px;
    // margin-right: 12px;
}

svg {
    margin-right: 5px;
}

.edit-remove-inline {
    display: inline-flex;
    color: $dark-grey;
    visibility: hidden;

    .action {
        margin-right: .1rem;
        &:last-child {
            margin-right: .6rem;
        }
    }
}

.drop-item {
    padding-right: 0px !important;
}


.collection-filter-btns .row .col {
    padding-left: 10px;
    padding-right: 10px;
}


.btn-group {
    .btn {
        &.filter-cols {
            margin: 0 0.5rem 0 0;
            display:inline-block;
            border-radius: 0;
            background-color: $light-grey !important;
            font-weight: $semi-bold;
            font-size: .8rem;
            padding: 0.3rem .75rem;
    
            &:hover {
                &:enabled {
                    background-color: $light-grey !important;
                    color: $dark-grey !important;
                }
            }       
            &.active {
                background-color: $secondaryColor !important;
                border:none;
                color: white;
            }
        }
    
    }

}



.hover-effect {
    color: $dark-grey;

    &[disabled] {
        color: $grey;
    }

    &:not([disabled]):hover {
        color: $primaryColor;
    }
}
.mini-collection-menu {
    min-width:350px;
}

.mini-collection-menu, 
.mini-project-action-popup {
    color:$dark-grey;
    p {
        padding: 0.5rem 1rem;
        margin-bottom:0;
        &.warning-label {
            strong {
                color: $primaryColor;
            }
        }
    }
    h6 {
        padding: 0.5rem 1rem;
    }

    .mini-collection-menu-title {
        // font-size: $font-size-small-bigger-btn;
        // font-weight: $regular;   
        color: $dark-grey;
        padding: 1rem 1rem 0rem 1rem;
        // padding-bottom: 0;
    
        &.copy-project-title {
            color: $primaryColor;
        }
    
        // strong {
        //     font-weight: $bold;
        // }
    }

}

@media (max-width:575px) {
    .collection-selector {
        width:100%;
        button {
            margin:1rem 0;
        }
    }

}