@import '../../styles/variables.scss';

.question-container {
    display: flex;
    flex-direction: column;
    margin-top:0.75rem;
    label {
        color:$primaryColor;
    }
}

.question-set-container {
    display:flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
}