@import "../../styles/variables.scss";

.submit-collection-modal-container {
    .submit-description,
    .submit-form {
        display: flex;
        justify-content: center;
    }
}

