@import '../../styles/variables.scss';


:global {

    .progress-modal {
        .modal-content {
            max-width: 400px;
            margin: 0 auto;
            padding: 2px;
            
            border-radius: .75rem;

            .modal-body {

                border-radius: inherit;

                .md-progress {

                    margin-bottom: 0px;

                    &.progress {

                        height: 30px;
                        line-height: normal;
                        border-radius: inherit;

                        .progress-bar {

                            background-color: $secondaryColor;
                            height: 100%;
                            transition: none;
                            // transition: width 0.01s ease;
                        }

                        .status {
                            position: absolute;
                            left: 0px;
                            right: 0px;
                            top: 0px;
                            bottom: 0px;
                            display: flex;
                            
                            justify-content: center;
                            color: $white;
                            font-size: 1.2rem;
                            font-weight: $semi-bold;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            :first-child {
                                position: relative;
                                align-self: center;
                                margin: 0 auto;
                            }
                        }
                    }
                }
            }
        }
    }

}