@import '../../styles/variables.scss';

.groups-tab {
    table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;

        th {
            padding-bottom: 0.5rem;
            color: $dark-grey;
            font-size: $font-size-btn;
        }

        tbody > tr:not(.detail-panel):not([role="expanded"]) {
            background-color: $off-white;

            cursor: pointer;

            font-size: 1.2rem;

            border: 1px solid $grey;

            td {
                // border-right: 0;
                // border: 1px solid $grey;
                // border-top-left-radius: 0.15rem;
                // border-bottom-left-radius: 0.15rem;
                padding: 0.75rem 1rem;
            }
        }
    }

    .create-group-btn {
        float:right;
        margin-bottom:1rem;
    }

    .remove-user-icon {
        cursor: pointer;
        color: $dark-grey;
        &:hover {
            color: $alert;
        }
    }

    .group-name-icon {
        color: $secondaryColor;
    }
    // .group-modal > div > .modal-body {
    //     padding: 20px 30px;
    // }

    .MuiPaper-root {
        width: 100%;
    }

    .table-group-name-header,
    .table-group-name,
    .table-group-member-count,
    .table-group-actions-icons {
        font-size: $font-size-btn;
        font-weight: $semi-bold;

        color: $dark-grey
    }

    .table-group-actions-icons {
        float: right;
    }
}

.add-group-input-label {
    font-weight: $semi-bold;
}

.group-modal-form {
    padding:0.5rem 2rem 1rem 2rem;
}

.app-modal {
    &.mini-modal {
        text-align:left;
        .modal-content {
            text-align:left;
            .modal-body {
                .modal-title-container {
                    align-items:top;
                    padding:0.5rem 1rem 0.5rem 1.5rem;

                    .modal-title {
                        color: $dark-grey;
                        font-size:1.6rem;
                        text-align:left;
                    }
                    .modal-close {
                        margin-top:0;
                    }
                }
                
            }
            form {
                padding:0.5rem 2rem 1rem 2rem;

                .form-group {
                    text-align:left;
                    input:not(.app-checkbox){
                        width:100%;
                    }
                    label {
                        font-weight:$regular;
                        margin-top:0.75rem;
                    }
                    h3 {
                        color: $dark-grey;
                        font-size:1.1rem;
                        font-weight: $semi-bold;
                        margin-top:1rem;
                    }
                    .checkbox-group {
                        margin-top:1rem;
                        text-align:left;           
                        align-items: left;
                

                        label {
                            // display:inline;
                        }
                    }
                    .help-block {
                        margin-left:0;
                    }
                }
            }
        }
    }  
}

@media (min-width:576px) {
    .app-modal {
        &.modal-dialog {
            &.mini-modal {
                max-width:600px;
            }
        }
    }
}

@media (min-width:768px) {
    .app-modal {
        &.modal-dialog {
            &.mini-modal {
                max-width:600px;
            }
        }
        .modal-content {
            .groups-tab {
                .create-group-btn {
                    // width:50%;
                }
                h2.group-title {
                    margin-bottom:0
                }
            }    

        }
    }
    
}
@media (min-width:1200px) {
    .groups-tab {
        .create-group-btn {
            // width:25%;
        }
    }    
}