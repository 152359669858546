@import "../../styles/variables.scss";
@import "../../styles/scss/fontawesome.scss";


.dropdown {
    padding:0;

    // &-item {
    //     font-size: $font-size-btn !important;
    //     font-weight: $regular !important;
    //     width: 100%;
    // }
    // &-header {
    //     color: $dark-grey !important;
    //     font-size: $font-size-btn !important;
    //     font-weight: $light !important;
    // }

    .dropdown-menu {
        padding: 0;

        > div,a {
            outline:none !important;
            outline-color: transparent;
            outline-style: none;

            &:focus,
            &:active {
                outline:none !important;
                outline-color: transparent;
                outline-style: none;
            }
        }
        .dropdown-item {
            color: $primaryColor; 
            padding: 0.5rem 1rem;
            &:hover, 
            &.active {
                box-shadow: none;
         }
    }

    .dropdown-item {
        transition: none !important;
        width: 100%;
        
        &:hover {
            background-color: $light-grey;  
            color:$dark-grey; 
        }
    }
        
        // a.user-menu-item, a.user-menu-item:hover {
        //     color: $grey !important;
        // }
        // a {

        //     &:hover {
        //         background-color: '$light-grey' !important;
        //     }
        //     &.dropdown-item {
        //         color: $primaryColor;
        //     }
        //     &.dropdown-item:hover {
        //         box-shadow:none;
        //     }
        //     &.active {
        //         cursor: default;
        //     }
        //     cursor: pointer;
        // }
        

    } 
    &.user-menu {
        & > button {
            padding-right:0;
            padding-left:0;
            &:active {
                background: $white;
                border-color:$white;
                color: $primaryColor;

                &:focus,
                &:hover {
                    background: $white !important;
                    border-color:$white;
                    color: $primaryColor;
                }
            }

            .svg-inline--fa {
                height: auto;
                &.fa-user-circle {
                    width:1.2rem;
                }
            }
            .user-profile {
                display:none;
                padding: 0px 12px;
            }

        }
        .dropdown-header {
            color: $grey;
            font-weight: $semi-bold;
            padding: 1rem 1rem 0 1rem;;
        }
        
        .dropdown-item {
            
            &:hover {
                background-color: $light-grey;  
                color:$dark-grey !important; 
            }
            &.active {
                background-color:$primaryColor;
                color:$white !important; 

                &:after {
                    font-family: "Font Awesome 5 Pro";
                    content: " \f00c";
                    font-weight: 600;
                    font-style: normal;
                    margin-left:0.5rem;
                }
            }
        }

        .user-menu-item {
            color: $dark-grey;
        }
        .scrollable-menu {
            margin-bottom:0;
        }
        .user-actions {
            border-top: 1px $light-grey solid;
        }
    }
}


.display-inline {
    display: flex;
    align-content: center;
    flex-direction: row;
}



@media (min-width:576px) {
    .user-profile {
        display:flex !important;
    }
}

