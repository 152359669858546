@import "../../styles/variables.scss";
@import "../../styles/scss/fontawesome.scss";

.full-modal {
    position: absolute !important;
    top: 0px;
    bottom: 0px; 
    left: 0px;
    right: 0px;
    max-width: 100% !important;
    margin: 0px !important;
    
	.modal-content {
        height: auto;
        min-height: 100%;
    }
}

.backdrop-group-modal {
    z-index: 1050;
}

.group-modal-wrapper {
    z-index: 1060;
}


.mini-modal {
	.modal-content {
        opacity: 1;
        height: auto;
        min-height: 100%;

        .modal-body {
            .modal-title-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 15px;
                text-align: center;

                .modal-title {
                    margin: 0;
                    padding: 0;
                    width: 100%;
                }

                .modal-close {
                    width: 40px;
                    position: initial;
                }
            }
        }
        input:not([type=submit]),
        input:not([type=button]),
        input:not([type=checkbox]),
        select, 
        textarea,
        .custom-select {
            width:100%;
        }

    }
}

.app-modal:not(.progress-modal):not(.mini-modal) {
    .modal-content .content {
        padding: 0 15rem;
    }
}

.app-modal {
    .modal-close {
        color: $grey;
        font-weight: $light;
        height: 60px;
        width: 60px;
        margin-top: 0.3rem;
        margin-right: 0.3rem;
        padding: 0.15rem;
        position: absolute;
        right: 0.5rem;
        top: 0.5rem;
        cursor:pointer;
    }

    .modal-content {
        h2 {
            color:$dark-grey;
            font-size:1.6rem;
            margin:1.5rem 0 1rem;
        }
        .modal-body {
            display: flex;
            flex-direction: column;
            padding: 0px;
            // opacity: .95;
            
            .modal-title {         
                font-weight:$regular;
                line-height: 1.5;
                margin: 0.5rem 0 1.5rem 0;
                text-align: center;
            }

            .main-section {
                margin: 0 auto;
                text-align: center;
                max-width: 1140px;
                width: 100%;
            }

            .footer-section {
                padding: 3rem;
                background: $white;
                text-align: center;
            }
        }
    }
}

@media (min-width:992px) {
    .modal {
        .modal-frame {
            &.mini-modal {
              
                position:relative;
                margin: 1.75rem auto !important;
                width:auto;
                max-width:600px !important;
            }

        }
    }      
}