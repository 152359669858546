.migration-modal {
    padding: 15px;
    width: 100%;

    .migration-modal-actions {
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        button {
            &:not(:first-child) {
                margin-left: 20px;
            }
        }
    }

    .migration-modal-backup {
        margin-top: 20px;

        button {
            margin-left: 20px;
        }
    }
}
