@font-face {
    font-family: "Source Sans Pro";
    font-weight: 300;
    src: url("./fonts/source-sans-pro-light.ttf") format("truetype");
}

@font-face {
    font-family: "Source Sans Pro";
    font-weight: 400;
    src: url("./fonts/source-sans-pro-regular.ttf") format("truetype");
}

@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    src: url("./fonts/source-sans-pro-semibold.ttf") format("truetype");
}

@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 700;
    src: url("./fonts/source-sans-pro-bold.ttf") format("truetype");
}

@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 900;
    src: url("./fonts/source-sans-pro-black.ttf") format("truetype");
}

@font-face {
    font-family: "Font Awesome 5 Pro";
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url("./webfonts/fa-regular-400.eot");
    src: url("./webfonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("./webfonts/fa-regular-400.woff2") format("woff2"), url("./webfonts/fa-regular-400.woff") format("woff"), url("./webfonts/fa-regular-400.ttf") format("truetype"), url("./webfonts/fa-regular-400.svg#fontawesome") format("svg");
}